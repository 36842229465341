




























import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "IndividualTalentsGrowth",
  props: {
    individualist_talent: {
      type: Array as PropType<string[]>,
      required: true
    },
    talent: {
      type: Boolean,
      default: true
    }
  }
});
